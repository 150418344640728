import React, {useState} from 'react'
import {JourneyQuestionFragment} from 'gatsby-graphql-types-generated'
import {graphql} from 'gatsby'

import {Box, Flex} from 'src/components/Box'
import {useUpdateJourneyResponseMutation} from 'src/graphql-generated'
import SimpleCard from 'src/components/SimpleCard'
import {Body, SmallBody} from 'src/components/text'
import CheckboxInput from 'src/components/CheckboxInput'
import {Action} from 'src/components/Action'
import {useStudentJourney} from 'src/hooks/useStudentJourney'
import useViewer from 'src/hooks/useViewer'
import Loading from 'src/components/Loading'
import AccountPromptModal from 'src/components/AccountPromptModal'

interface Props {
  question: JourneyQuestionFragment
  journeyId: string
}

export const query = graphql`
  fragment JourneyQuestion on ContentfulJourneyQuestion {
    __typename
    id
    body {
      body
    }
    answers {
      id
      body {
        body
      }
    }
  }
`

const JourneyQuestion = ({question, journeyId}: Props) => {
  const {loggedIn} = useViewer()
  const studentJourney = useStudentJourney(journeyId)
  const [updateResponse, {loading: updatingResponse}] =
    useUpdateJourneyResponseMutation()
  const [showUnauth, setShowUnauth] = useState(false)
  const responseAnswerId = studentJourney && studentJourney.contentfulAnswerId
  if (updatingResponse) {
    return <Loading size="medium" />
  }

  return (
    <>
      <SimpleCard data-cy="journey-question" p="2" pb="0" width={{md: '675px'}}>
        <Flex flexDirection="column">
          <SmallBody color="grey5" css={{textAlign: 'center'}}>
            Personalize Your Journey
          </SmallBody>
          {question.body && (
            <Body my="2" css={{textAlign: 'center'}}>
              {question.body.body}
            </Body>
          )}
          {question.answers &&
            question.answers.map((answer) => {
              // NOTE: if there is a response, only show the checked answer
              if (responseAnswerId && responseAnswerId !== answer.id) {
                return null
              }
              return (
                <Box
                  key={answer.id}
                  px="2"
                  mx={-2}
                  py="1"
                  borderTop="2px solid"
                  borderColor="grey2"
                >
                  <Action
                    onClick={() => {
                      if (!loggedIn) {
                        setShowUnauth(true)
                        return
                      }
                      updateResponse({
                        variables: {
                          questionId: question.id,
                          answerId: answer.id,
                          journeyId: journeyId,
                        },
                      })
                    }}
                    css={{width: '100%'}}
                  >
                    <Flex alignItems="center">
                      <CheckboxInput
                        readOnly
                        checked={answer.id === responseAnswerId}
                      />

                      {answer.body && <Body ml="1">{answer.body.body}</Body>}
                    </Flex>
                  </Action>
                </Box>
              )
            })}
          <AccountPromptModal
            isOpen={showUnauth}
            onRequestClose={() => setShowUnauth(false)}
            prompt="Create an account to personalize your journey"
          />
        </Flex>
      </SimpleCard>
      {responseAnswerId && (
        <Box>
          <Action
            disabled={!loggedIn}
            onClick={() => {
              updateResponse({
                variables: {questionId: question.id, journeyId: journeyId},
              })
            }}
          >
            <SmallBody>Reset this filter</SmallBody>
          </Action>
        </Box>
      )}
    </>
  )
}

export default JourneyQuestion
