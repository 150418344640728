import styled from '@emotion/styled'

import {Theme} from 'src/styles/theme'

const DisabledOverlay = styled.div(({theme}: {theme: Theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.colors.white,
  opacity: 0.6,
}))

export default DisabledOverlay
