import {JourneyTemplatePageDataQuery} from 'gatsby-graphql-types-generated'
import {graphql, navigate} from 'gatsby'

import JourneyPageTemplate from 'src/components/JourneyPageTemplate'

export const query = graphql`
  query JourneyTemplatePageData($id: String) {
    contentfulJourney(id: {eq: $id}) {
      ...StaticJourneyPage
    }
  }
`

interface Props {
  data: JourneyTemplatePageDataQuery
}

const JourneyPage = ({data}: Props) => {
  const journey = data.contentfulJourney

  if (!journey) {
    navigate('404')
    return null
  }

  return <JourneyPageTemplate journey={journey} />
}

export default JourneyPage
