import {
  StaticJourneyPageFragment,
  Cms_Group,
} from 'gatsby-graphql-types-generated'
import {graphql} from 'gatsby'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import JourneyStart from 'src/components/Journey/JourneyStart'
import MilestoneCard from 'src/components/Journey/MilestoneCard'
import Article from 'src/components/Journey/Article'
import ResourcePageCard from 'src/components/Journey/ResourcePageCard'
import {Grid, Box, Flex} from 'src/components/Box'
import Group from 'src/components/Journey/Group'
import {FlairProvider} from 'src/context/FlairContext'
import JourneyQuestion from 'src/components/JourneyQuestion'
import {useStudentJourney} from 'src/hooks/useStudentJourney'
import usePersonalizationFilter from 'src/hooks/usePersonalizationFilter'
import useProcessJourneyChildren, {
  JourneyChildType,
} from 'src/hooks/useProcessJourneyChildren'
import useTrackJourneyInteraction from 'src/hooks/useTrackJourneyInteraction'
import {localPaths} from 'src/urls'
import JobSearchCta from 'src/components/JobSearchCta'

const JourneyChild = ({child}: {child: JourneyChildType}) => {
  if (child === null || child === undefined) {
    return <></>
  }
  switch (child.__typename) {
    case 'ContentfulGroup':
      return (
        <Box gridColumn="span 2">
          <Group
            title={child.name}
            description={child.description?.description}
          />
        </Box>
      )
    case 'ContentfulMilestone':
      return (
        <Box gridColumn="span 2">
          <MilestoneCard
            heroUrl={
              child.heroFile?.childImageSharp?.fluid || child.heroRemoteUrl
            }
            title={child.name}
            description={child.description?.description}
            link={child.permalink}
            articleCount={child.articleCount}
          />
        </Box>
      )
    case 'ContentfulJourneyTile':
      return (
        <Box gridColumn={{_: 'span 2', md: 'span 1'}}>
          {child.tile && (
            <Article
              heroUrl={
                child.tile.heroFile?.childImageSharp?.fluid ||
                child.tile.heroRemoteUrl
              }
              title={child.tile.title}
              description={child.tile.summary?.summary}
              link={child.permalink}
            />
          )}
        </Box>
      )
    case 'ContentfulResourcePage':
      return (
        <Box gridColumn={{_: 'span 2', md: 'span 1'}}>
          <ResourcePageCard resourcePage={child} />
        </Box>
      )
    default:
      throw new Error(`Unknown __typename for Journey child.`)
  }
}

export const query = graphql`
  fragment StaticJourneyPage on ContentfulJourney {
    __typename
    id
    contentful_id
    name
    description {
      description
    }
    permalink
    heroRemoteUrl
    heroFile {
      ...HeroImage
    }
    icon
    questions {
      ...JourneyQuestion
    }

    contentfulchildren {
      ...MilestoneCard
      ...ArticleCard
      ...GroupCard
      ... on ContentfulGroup {
        contentfulchildren {
          ...ArticleCard
        }
      }
    }

    ...JourneyGrouping
  }
`

interface Props {
  journey: StaticJourneyPageFragment
}

const JourneyPageTemplate = ({journey}: Props) => {
  useTrackJourneyInteraction(journey.__typename, journey.contentful_id)
  const studentJourney = useStudentJourney(journey.contentful_id)

  const filteredJourney = usePersonalizationFilter(
    journey,
    studentJourney?.contentfulAnswerId
  )

  const journeyChildren = useProcessJourneyChildren(
    filteredJourney,
    'ContentfulGroup' as Cms_Group['__typename']
  )

  return (
    <Layout>
      <SEO
        title={journey.name}
        description={journey.description?.description || undefined}
        permalink={journey.permalink}
      />
      <FlairProvider seed={parseInt(journey.id, 16)}>
        <JourneyStart
          heroUrl={journey.heroRemoteUrl}
          heroFile={journey.heroFile}
          title={journey.name}
          description={journey.description?.description}
        />

        {journey.permalink === localPaths.journeys.findAJob && (
          <Flex mt="3" justifyContent="center">
            <JobSearchCta
              heading="Ready to find your next job?"
              text="Check out our job search tool to browse entry-level jobs near you."
              linkLabel="Search now!"
              linkUrl={localPaths.jobSearch}
            />
          </Flex>
        )}

        {journey.questions?.map((question) => (
          <Flex
            mt={{_: '3', lg: 6}}
            flexDirection="column"
            alignItems="center"
            key={question.id}
          >
            <JourneyQuestion
              journeyId={journey.contentful_id}
              question={question}
            />
          </Flex>
        ))}

        <Grid
          gridGap="5"
          gridTemplateColumns={{_: '1fr', md: '1fr 1fr'}}
          mt="5"
        >
          {journeyChildren.map((child) => (
            <JourneyChild key={child.id} child={child} />
          ))}
        </Grid>
      </FlairProvider>
    </Layout>
  )
}

export default JourneyPageTemplate
